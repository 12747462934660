//import LineGradient from "../components/LineGradient";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";
import Alien3DCard from "../components/Alien3DCard";

//import { GLTFModel, AmbientLight, DirectionLight } from "react-3d-viewer";
//import Card from "../components/Card";

/*function Model(props) {
  const { scene } = useGLTF("assets\\ap_v2_8001.glb");
  return <primitive object={scene} {...props} />;
}*/

const AlienPunks3D = () => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <section
      id="more stuff"
      className="md:justify-center md:h-full pt-36 pb-36"
    >
      {/* ARTS */}

      <div className="md:w-2/5 mx-auto text-center">
        <p className="text-punks-box-border font-pressStart font-normal text-4xl mx-auto underline">
          MORE STUFF
        </p>
      </div>

      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
      >
        <div className="grid grid-cols-2 gap-2 px-2 py-2 mt-6 text-center md:max-w-[750px] mx-auto rounded-md bg-punks-box-bg outline outline-punks-box-border outline-1 text-punks-box-border">
        <Alien3DCard GLTFsrc={"assets/Alien_Dude.glb"} bgColor={"bg-[#A754EF]"}></Alien3DCard>
          {/*
          <Alien3DCard GLTFsrc={"assets/Alien_Necro.glb"} bgColor={"bg-[#A754EF]"}></Alien3DCard>
          <Alien3DCard GLTFsrc={"assets/Alien_Vampire.glb"} bgColor={"bg-[#A754EF]"}></Alien3DCard>
          <Alien3DCard GLTFsrc={"assets/Alien_GoldenBoy.glb"} bgColor={"bg-[#A754EF]"}></Alien3DCard>
          <Alien3DCard GLTFsrc={"assets/Car_Lambo.glb"} bgColor={"bg-[#A754EF]"}></Alien3DCard>
          <Alien3DCard GLTFsrc={"assets/Car_Time.glb"} bgColor={"bg-[#A754EF]"}></Alien3DCard>
          
        */}

          <div>
            {/*<GLTFModel
              src="assets\ap_v2_8001.glb"
              width="350"
              height="350"
              className="align-top"
            >
              <AmbientLight color={0xffffff} />
              <DirectionLight
                color={0xffffff}
                position={{ x: 100, y: 200, z: 100 }}
              />
              <DirectionLight
                color={0xff00ff}
                position={{ x: -100, y: 200, z: -100 }}
              />
            </GLTFModel>*/}
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default AlienPunks3D;

import AnchorLink from "react-anchor-link-smooth-scroll";

const DotGroup = ({ selectedPage, setSelectedPage }) => {
  const selectedStyles = `relative bg-orange-400 before:absolute before:w-6 before:h-6 before:rounded-full
    before:border-2 before:border-orange-400 before:left-[-50%] before:top-[-50%]`;

  return (
    <div className="flex flex-col gap-6 fixed top-[20%] right-7">
      <AnchorLink
        href="#gravitators"
        className={`${
          selectedPage === "gravitators" ? selectedStyles : "bg-white"
        } w-3 h-3 rounded-full`}
        onClick={() => setSelectedPage("gravitators")}
      />

      <AnchorLink
        href="#alien punks"
        className={`${
          selectedPage === "alien punks" ? selectedStyles : "bg-white"
        } w-3 h-3 rounded-full`}
        onClick={() => setSelectedPage("alien punks")}
      />

      {/*<AnchorLink
        href="#music"
        className={`${
          selectedPage === "music" ? selectedStyles : "bg-white"
        } w-3 h-3 rounded-full`}
        onClick={() => setSelectedPage("music")}
      />

      <AnchorLink
        href="#writing"
        className={`${
          selectedPage === "writing" ? selectedStyles : "bg-white"
        } w-3 h-3 rounded-full`}
        onClick={() => setSelectedPage("writing")}
      />

      <AnchorLink
        href="#art"
        className={`${
          selectedPage === "art" ? selectedStyles : "bg-white"
        } w-3 h-3 rounded-full`}
        onClick={() => setSelectedPage("art")}
      />
      */}
      <AnchorLink
        href="#contact"
        className={`${
          selectedPage === "contact" ? selectedStyles : "bg-white"
        } w-3 h-3 rounded-full`}
        onClick={() => setSelectedPage("contact")}
      />
    </div>
  );
};

export default DotGroup;

import { useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";

const Link = ({ page, selectedPage, setSelectedPage }) => {
  const lowerCasePage = page.toLowerCase();
  return (
    <AnchorLink
      className={`${
        selectedPage === lowerCasePage ? "text-orange-400" : ""
      } hover:text-orange-400 transition duration-500`}
      href={`#${lowerCasePage}`}
      onClick={() => setSelectedPage(lowerCasePage)}
    >
      {page}
    </AnchorLink>
  );
};

const Navbar = ({ isTopOfPage, selectedPage, setSelectedPage }) => {
  const [isMenuToggled, setIsMenuToggled] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  //TODO: Remove navbarBackground when scrolling down. If so, also remove ${navbarBackground} below.
  const navbarBackground = isTopOfPage ? "bg-insular-gray" : "bg-insular-gray";

  return (
    <nav
      className={`${navbarBackground} z-40 w-full fixed top-0 py-1 border-b-2 border-white`}
    >
      <div className="flex items-center justify-between mx-auto w-5/6 h-1/4">
        {/*<h4 className="font-quicksand text-4xl" style={{ userSelect: "none" }}>farresto</h4>*/}
        <a href="https://www.insulargames.com">
          <motion.img
            src="assets/insular_logo_web.png"
            alt="Insular Games"
            className="h-24 "
          ></motion.img>
        </a>

        {/* DESKTOP NAV */}
        {isDesktop ? (
          <div className="flex justify-between gap-16 font-opensans text-md font-semibold md:mt-16 -py-4">
            <Link
              page="Gravitators"
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />
            <Link
              page="Alien Punks"
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />
            {/*<Link
              page="Music"
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />
            <Link
              page="Writing"
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />
            <Link
              page="Art"
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
        />*/}
            <Link
              page="Contact"
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />
          </div>
        ) : (
          <button
            className="rounded-full bg-orange-400 p-4 mr-4"
            onClick={() => setIsMenuToggled(!isMenuToggled)}
          >
            <img alt="menu-icon" src="../assets/menu-icon.svg" />
          </button>
        )}

        {/* MOBILE MENU POPUP */}
        {!isDesktop && isMenuToggled && (
          <div className="fixed right-0 bottom-0 h-full bg-darkest-grey w-[300px]">
            {/* CLOSE ICON */}
            <div className="flex justify-end p-12">
              <button onClick={() => setIsMenuToggled(!isMenuToggled)}>
                <img alt="close-icon" src="../assets/close-icon.svg" />
              </button>
            </div>

            {/* MENU ITEMS */}
            <div className="flex flex-col gap-10 ml-[33%] text-2xl text-white">
              <Link
                page="Gravitators"
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
              <Link
                page="Alien Punks"
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
              {/*<Link
                page="Music"
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
              <Link
                page="Writing"
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
              <Link
                page="Art"
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
        />*/}
              <Link
                page="Contact"
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;

//import SocialMediaIcons from "../components/SocialMediaIcons";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";
//import AnchorLink from "react-anchor-link-smooth-scroll";
//import lineClass from "../components/Line.css";
import { Carousel } from "react-carousel-minimal";
import ReactPlayer from "react-player/youtube";
//import Line from "../components/Line.jsx";
//import Tilt from "react-vanilla-tilt";
//import Tilt, { TiltOptions } from "vanilla-tilt";
//import Tilt from "react-vanilla-tilt";
//import LineGradient from "../components/LineGradient";
import GravitatorsCardGIF from "../components/GravitatorsCardGIF";

const data = [
  {
    image: "assets/1920x1080_Screenshot_01_City.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_02_Fortress.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_03_Escort Nuke.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_04_Explosions.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_05_Darkness.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_06_Lava.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_07_Fortress.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_08_Beam.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_09_Wormhole.jpg",
    caption: "",
  },
];

const captionStyle = {
  fontSize: "2em",
  fontWeight: "bold",
};
const slideNumberStyle = {
  fontSize: "20px",
  fontWeight: "bold",
};

const GravitatorsHome = ({ setSelectedPage }) => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <section id="gravitators" className="md:justify-center md:h-full pt-32">
      {/* IMAGE SECTION */}
      <div className="flex items-center justify-center mx-auto">
        {/*<div className=" z-10 mt-16 md:mt-36 mr-4 justify-center">*/}
        {isAboveLarge ? (
          <img
            className="md:justify-center md:max-w-[600px]"
            src="assets/gravitators_logo.png"
            alt="profile"
          ></img>
        ) : (
          <img
            alt="profile"
            className="z-10 w-full max-w-[400px] md:max-w-[600px] rounded-full
              justify-items-center
            ring-2 ring-offset-2 ring-offset-dark-blue ring-white"
            src="assets/gravitators_logo.png"
          />
        )}
      </div>
      <div>
        <ReactPlayer
          url="https://youtu.be/-RXkFRBiZbo"
          playing="true"
          controls="true"
          volume={0}
          muted={true}
          className="mx-auto justify-items-center"
          width="960px"
          height="540px"
        />
      </div>

      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
      >
        <div className=" mt-16 md:max-w-[600px] mx-auto rounded-md bg-gravitators-box-bg outline outline-gravitators-box-border outline-1">
          {/* HEADINGS */}

          <p
            className="font-redHatDisplay font-medium text-xl text-center md:text-center justify-center px-4 py-3
           text-gravitators-box-border border-b border-gravitators-box-border"
          >
            A Gravity Shooter Experience
          </p>
          <Carousel
            data={data}
            time={3000}
            width="600px"
            height="400px"
            captionStyle={captionStyle}
            radius="0px"
            slideNumber={false}
            slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            automatic={true}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="30px"
            slideBackgroundColor="black"
            slideImageFit="cover"
            thumbnails={false}
            thumbnailWidth="96px"
            style={{
              textAlign: "center",
              maxWidth: "750px",
              maxHeight: "400px",
              margin: "0px auto",
            }}
          />
          <p
            className="font-redHatDisplay text-l text-center px-4 py-2
          text-gravitators-box-border border-t border-gravitators-box-border"
          >
            Fight enemies, transport heavy objects and maneuver through
            obstacles, all in different gravity environments.
          </p>
        </div>
      </motion.div>

      <div className="md:mt-16"></div>

      {/* MAIN TEXT */}
      {/*<div className="z-30 basis-4/5 mt-96 md:mt-64 md: ml-96 mr-96 justify-center bg-white">*/}

      <GravitatorsCardGIF
        cardtitle={"Innovating on a classic genre"}
        cardimage={"assets/GIF-01-Fighter-Special.gif"}
        carddescription={
          "Building upon great genre classics like Thrust, Gravitar, Solar Jetman and Subterrania, it introduces a lot of new refreshing gameplay."
        }
      />
      <GravitatorsCardGIF
        cardtitle={"4 Playable Gravitator Ships"}
        cardimage={"assets/GIF-02-Sparker-Alarm.gif"}
        carddescription={
          "Each with 3 unique weapons and different piloting controls."
        }
      />
      <GravitatorsCardGIF
        cardtitle={"60 missions of pure gravity mayhem"}
        cardimage={"assets/GIF-03-Lancer-Ferr-with-Spikes.gif"}
        carddescription={"With different objectives in diverse environments."}
      />
      <GravitatorsCardGIF
        cardtitle={"Power Ups and Perks"}
        cardimage={"assets/GIF-04-Crusher-Underwater.gif"}
        carddescription={"To aid you in the grueling war ahead."}
      />
      <GravitatorsCardGIF
        cardtitle={"Hand drawn 2D low poly graphics"}
        cardimage={"assets/GIF-05-Sun.gif"}
        carddescription={"Every sprite carefully crafted to ensure an enchanting art style."}
      />

      <div className="flex items-center justify-center mx-auto h-1/4 md:mt-16">
        <iframe
          title="Steam Widget"
          src="https://store.steampowered.com/widget/1321890/"
          frameborder="0"
          width="750"
          height="200"
        ></iframe>
      </div>

      <div className="flex items-center justify-center mx-auto h-1/4 md: mt-2">
        <iframe
          title="Steam Deluxe Widget"
          src="https://store.steampowered.com/widget/1639960/"
          frameborder="0"
          width="750"
          height="200"
        ></iframe>
      </div>

      <div className="flex items-center justify-center mx-auto h-1/4 md: mt-2">
        <iframe
          title="Humble Widget"
          src="https://www.humblebundle.com/widget/v2/product/humblewidget/d9ZtWuwUJR?theme=light"
          width="750"
          height="310"
          frameborder="0"
        ></iframe>
      </div>

      <div className="flex items-center justify-center mx-auto h-1/4 md: mt-2">
        <iframe
          title="Itch Widget"
          height="180"
          frameborder="0"
          src="https://itch.io/embed/661732"
          width="750"
        >
          <a href="https://insular-games.itch.io/gravitators">
            Gravitators Demo by Insular Games
          </a>
        </iframe>
      </div>
    </section>
  );
};

export default GravitatorsHome;

import React from "react";
import Navbar from "../scenes/Navbar";
import Landing from "../scenes/Landing";
import DotGroup from "../scenes/DotGroup";
import AlienPunks from "../scenes/AlienPunks";
import LineGradient from "../components/LineGradient";
//import Music from "./scenes/Music";
//import Arts from "./scenes/Arts";
import Contact from "../scenes/Contact";
//import ContactForm from "./scenes/Contact2";
import Footer from "../scenes/Footer";
import useMediaQuery from "../hooks/useMediaQuery";
import { useEffect, useState } from "react";
//import Writing from "./scenes/Writing";
import { motion } from "framer-motion";
//import ContainerCard from "./components/ContainerCard";
//import Card2 from "./components/Card2";
//import Line from "./components/Line";



function App() {
    const [selectedPage, setSelectedPage] = useState("gravitators");
    //TODO: Confirm if needed. Remove all instances if not.
    const [isTopOfPage, setIsTopOfPage] = useState(true);
    const isDesktop = useMediaQuery("(min-width: 1060px)");
  
    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY === 0) {
          setIsTopOfPage(true);
          setSelectedPage("gravitators");
        }
        if (window.scrollY !== 0) setIsTopOfPage(false);
      };
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, []);
  
    return (
      <div className="bg-space bg-fixed">
        <Navbar
          isTopOfPage={isTopOfPage}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
        />
        <div className="w-5/6 mx-auto md:h-full">
          {isDesktop && (
            <DotGroup
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />
          )}
          <motion.div
            margin="0 0 0 0"
            amount="all"
            onViewportEnter={() => setSelectedPage("gravitators")}
          >
            <Landing setSelectedPage={setSelectedPage} />
          </motion.div>
        </div>
        
        <div className="w-5/6 mx-auto">
          <motion.div
            margin="0 0 -200px 0"
            amount="all"
            onViewportEnter={() => setSelectedPage("alien punks")}
          >
            <AlienPunks />
          </motion.div>
        </div>
      {/*
        <LineGradient />
        <div className="w-5/6 mx-auto -mb-24">
          <motion.div
            margin="0 0 -200px 0"
            amount="all"
            onViewportEnter={() => setSelectedPage("music")}
          >
            <Music />
          </motion.div>
        </div>
  
        <LineGradient />
        <div className="w-5/6 mx-auto -mb-24">
          <motion.div
            margin="0 0 -200px 0"
            amount="all"
            onViewportEnter={() => setSelectedPage("writing")}
          >
            <Writing />
          </motion.div>
        </div>
  
        <LineGradient />
        <div className="w-5/6 mx-auto -mb-24">
          <motion.div
            margin="0 0 -200px 0"
            amount="all"
            onViewportEnter={() => setSelectedPage("art")}
          >
            <Arts />
          </motion.div>
          </div>*/}
  
        <LineGradient />
        <div className="w-5/6 mx-auto mb-48">
          <motion.div
            margin="0 0 -200px 0"
            amount="all"
            onViewportEnter={() => setSelectedPage("contact")}
          >
            <Contact/>
  
          </motion.div>
        </div>
        
        <Footer />
      </div>
    );
  }
  
  export default App;
import LineGradient from "../components/LineGradient";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";
import Alien3DCard from "../components/Alien3DCard";
//import Card from "../components/Card";



const AlienPunks2D = () => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <section id="artwork" className="md:justify-center md:h-full pt-36 pb-36">
      {/* ARTS */}

      <div className="md:w-2/5 mx-auto text-center">
        <p className="text-punks-box-border font-pressStart font-normal text-4xl mx-auto underline">
          ARTWORK
        </p>
      </div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
      >
        <div className="grid grid-cols-2 gap-2 px-2 py-2 mt-6 text-center md:max-w-[750px] mx-auto rounded-md bg-punks-box-bg outline outline-punks-box-border outline-1 text-punks-box-border">
          <p>Pixel Portrait</p>
          <p>Voxel Model</p>
          <img src="assets\ap_v1_8001.png"></img>
          <Alien3DCard GLTFsrc={"assets/ap_v2_8001.glb"} bgColor={"bg-[#A754EF]"}></Alien3DCard>
          <img src="assets\ap_v1_8005.png"></img>
          <Alien3DCard GLTFsrc={"assets/ap_v2_8005.glb"} bgColor={"bg-[#638595]"}></Alien3DCard>
          <img src="assets\ap_v1_8006.png"></img>
          <Alien3DCard GLTFsrc={"assets/ap_v2_8006.glb"} bgColor={"bg-[#638595]"}></Alien3DCard>
          <img src="assets\ap_v1_8018.png"></img>
          <Alien3DCard GLTFsrc={"assets/ap_v2_8018.glb"} bgColor={"bg-[#638595]"}></Alien3DCard>

          {/*<img src="assets\ap_v1_8009.png"></img>
          <Alien3DCard GLTFsrc={"assets/ap_v2_8009.glb"} bgColor={"bg-[#A754EF]"}></Alien3DCard>
          <img src="assets\ap_v1_8010.png"></img>
          <Alien3DCard GLTFsrc={"assets/ap_v2_8010.glb"} bgColor={"bg-[#A754EF]"}></Alien3DCard>
          <img src="assets\ap_v1_8014.png"></img>
          <Alien3DCard GLTFsrc={"assets/ap_v2_8014.glb"} bgColor={"bg-[#638595]"}></Alien3DCard>
          <img src="assets\ap_v1_8017.png"></img>
          <Alien3DCard GLTFsrc={"assets/ap_v2_8017.glb"} bgColor={"bg-[#A754EF]"}></Alien3DCard>*/}

        </div>
      </motion.div>
    </section>
  );
};

export default AlienPunks2D;

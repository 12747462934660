//import SocialMediaIcons from "../components/SocialMediaIcons";
import useMediaQuery from "../hooks/useMediaQuery";

const FooterGravitators = () => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");

  return (
    <>
      {isAboveLarge ? (
        <footer className="-mt-48 bg-gravitators-box-bg border-t-2 border-gravitators-box-border">
          <div className="w-10/12 mx-auto">
            <div className="sm:grid">

                {/*<div className="mb-4 mt-6 justify-self-center align-top">
                  <SocialMediaIcons classInput="grayscale hover:grayscale-0 transition duration-500 w-8" />
                </div>*/}

              <div className="font-redHatDisplay text-s text-gravitators-box-border my-2 justify-self-center">
                © 2019{" "}
                <a
                  href="https://www.insulargames.com"
                  className="hover:text-orange-500 lowercase"
                >
                  insulargames.com{" "}
                </a>
                - Gravitators, Gravitators Logo and Insular Games logo are trademarks of Insular CV. All Rights Reserved
              </div>
            </div>
          </div>
        </footer>
      ) : (
        <footer className=" left-full right-full bottom-full  gravitators-box-bg w-[460px] h-[200px] border-t-2 border-gravitators-box-border">
          <div className="w-5/6 mx-auto">
            <div className="sm:grid sm:grid-cols-3 gap-4 justify-items-center">
            <div className="font-redHatDisplay text-s text-gravitators-box-border my-2 justify-self-center">
                © 2019{" "}
                <a
                  href="https://www.insulargames.com"
                  className="hover:text-orange-500 lowercase"
                >
                  insulargames.com{" "}
                </a>
                - Gravitators, Gravitators Logo and Insular Games logo are trademarks of Insular CV. All Rights Reserved
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default FooterGravitators;

//import SocialMediaIcons from "../components/SocialMediaIcons";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";
//import AnchorLink from "react-anchor-link-smooth-scroll";
//import lineClass from "../components/Line.css";
import { Carousel } from "react-carousel-minimal";
//import Line from "../components/Line.jsx";
//import Tilt from "react-vanilla-tilt";
//import Tilt, { TiltOptions } from "vanilla-tilt";
//import Tilt from "react-vanilla-tilt";
//import LineGradient from "../components/LineGradient";
import GravitatorsCardFAQ from "../components/GravitatorsCardFAQ";
import { Link, } from "react-router-dom";

const data = [
  {
    image: "assets/1920x1080_Screenshot_01_City.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_02_Fortress.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_03_Escort Nuke.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_04_Explosions.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_05_Darkness.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_06_Lava.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_07_Fortress.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_08_Beam.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_09_Wormhole.jpg",
    caption: "",
  },
];

const captionStyle = {
  fontSize: "2em",
  fontWeight: "bold",
};
const slideNumberStyle = {
  fontSize: "20px",
  fontWeight: "bold",
};

const GravitatorsFAQ = ({ setSelectedPage }) => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <section id="faq" className="md:justify-center md:h-full pt-28">
      {/* IMAGE SECTION */}
      <div className="flex items-center justify-center mx-auto">
        {/*<div className=" z-10 mt-16 md:mt-36 mr-4 justify-center">*/}
        {isAboveLarge ? (
          <img
            className="md:justify-center md:max-h-[80px]"
            src="assets/FAQ_Title.png"
            alt="profile"
          ></img>
        ) : (
          <img
            alt="profile"
            className="z-10 w-full max-w-[400px] md:max-w-[600px] rounded-full
              justify-items-center
            ring-2 ring-offset-2 ring-offset-dark-blue ring-white"
            src="assets/FAQ_Title.png"
          />
        )}
      </div>

      {/* MAIN TEXT */}
      {/*<div className="z-30 basis-4/5 mt-96 md:mt-64 md: ml-96 mr-96 justify-center bg-white">*/}

      <GravitatorsCardFAQ
        question="Which platforms does the game support?"
        answer={`At the moment, we released on Steam and Humble Store for Windows.
          
          The game is currently being ported to all consoles.
          Unfortunately, we don't currently have plans to release on Mac and Linux.`}
      ></GravitatorsCardFAQ>

      <GravitatorsCardFAQ
        question="What engine is the game made in?"
        answer="Unity."
      ></GravitatorsCardFAQ>
      <GravitatorsCardFAQ
        question="Does the game support controllers?"
        answer="Yes, Gravitators has Full Controller support, in both the demo and the full game."
      ></GravitatorsCardFAQ>
      <GravitatorsCardFAQ
        question="Where are the savegames stored?"
        answer={`They are in:
          
          C:\\Users\\username\\AppData\\LocalLow\\Insular Games\\Gravitators\\steamID\\Profiles.bin"
          
          (Note: folder “AppData” is sometimes hidden on Windows).

          We do support cloudsaving in Steam though.`}
      ></GravitatorsCardFAQ>
      <GravitatorsCardFAQ
        question="What are the minimum system requirements?"
        answer={`Most regular PCs and Laptops should be able to run it. We tried the game on a rather old PC and it worked quite well.
          
          The specs were:
          OS: Windows 7
          Processor: 2GHz
          Memory: 1000 MB RAM
          Graphics: 512MB VRAM
          Storage: 8000 MB available space
          `}
      ></GravitatorsCardFAQ>
      <GravitatorsCardFAQ
        question="My antivirus is blocking Gravitators exe files. What do I do?"
        answer={`In some rare situations, your anti-virus might think the exe is suspicious and block it. The exe is completely safe.

          You should add Gravitators folder as an exception, so your anti-virus won’t give you that false positive. Check your anti-virus help section if you don’t know how to do this.`}
      ></GravitatorsCardFAQ>

      <GravitatorsCardFAQ
        question="Where can I find those cool papercraft models I saw online?"
        answer="They are included in the Deluxe Version of the game (along with other digital goodies), but we’re giving the human ships away for free here."
      ></GravitatorsCardFAQ>

      <GravitatorsCardFAQ
        question="Do you have any free wallpapers I can download?"
        answer="Yes we do, right here. Many more wallpapers are also included in the Deluxe Version of the game (along with other digital goodies)."
      ></GravitatorsCardFAQ>

      <GravitatorsCardFAQ
        question="Can I create fan art based on Gravitators?"
        answer="You most certainly can, as long as it’s not done for commercial purposes."
      ></GravitatorsCardFAQ>

      <GravitatorsCardFAQ
        question="Where can I buy Gravitators?"
        answer={
          <p>
            You can find it on{" "}
            <Link
              to="https://store.steampowered.com/app/1321890/Gravitators/"
              target="_blank"
              className="hover:text-orange-500 text-white underline"
            >
              Steam
            </Link>{" "}
            and             <Link
              to="https://www.humblebundle.com/store/gravitators"
              target="_blank"
              className="hover:text-orange-500 text-white underline"
            >
              Humble Store
            </Link>. More stores might be added in the future."
          </p>
          
        }
      ></GravitatorsCardFAQ>

      <GravitatorsCardFAQ
        question="I’m a content creator. Can I get a Steam Key?"
        answer={`Sure thing! Go to our main page and fill the contact form to get in touch with us.`}
      ></GravitatorsCardFAQ>

     
    </section>
  );
};

export default GravitatorsFAQ;

import { motion } from "framer-motion";

const GravitatorsCardFAQ = ({ question, answer }) => {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.5 }}
      transition={{ duration: 0.25 }}
      variants={{
        hidden: { opacity: 1 },
        visible: { opacity: 1 },
      }}
    >
      <div className=" mt-4 md:max-w-[750px] mx-auto rounded-md bg-gravitators-box-bg outline outline-gravitators-box-border outline-1">
        {/* HEADINGS */}

        <p
          className="font-redHatDisplay text-l font-semibold px-4 py-2
          text-gravitators-box-border"
        >
          {question}
        </p>
        <p
          className="font-redHatDisplay text-l px-4 py-2
          text-gravitators-box-border"
          style={{whiteSpace: "pre-line"}}
        >
          {answer}
        </p>
      </div>
    </motion.div>
  );
};

export default GravitatorsCardFAQ;

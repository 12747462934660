//import SocialMediaIcons from "../components/SocialMediaIcons";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";
//import AnchorLink from "react-anchor-link-smooth-scroll";
//import lineClass from "../components/Line.css";
import { Carousel } from "react-carousel-minimal";
//import Line from "../components/Line.jsx";
//import Tilt from "react-vanilla-tilt";
//import Tilt, { TiltOptions } from "vanilla-tilt";
//import Tilt from "react-vanilla-tilt";
//import LineGradient from "../components/LineGradient";
import GravitatorsCardFAQ from "../components/GravitatorsCardFAQ";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import ReactIntense from "react-intense";

const data = [
  {
    image: "assets/1920x1080_Screenshot_01_City.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_02_Fortress.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_03_Escort Nuke.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_04_Explosions.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_05_Darkness.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_06_Lava.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_07_Fortress.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_08_Beam.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_09_Wormhole.jpg",
    caption: "",
  },
];

const captionStyle = {
  fontSize: "2em",
  fontWeight: "bold",
};
const slideNumberStyle = {
  fontSize: "20px",
  fontWeight: "bold",
};

const GravitatorsPressKit = ({ setSelectedPage }) => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <section id="press kit" className="md:justify-center md:h-full pt-28">
      {/* IMAGE SECTION */}
      <div className="flex items-center justify-center mx-auto">
        {/*<div className=" z-10 mt-16 md:mt-36 mr-4 justify-center">*/}
        {isAboveLarge ? (
          <img
            className="md:justify-center md:max-h-[80px]"
            src="assets/Press_Kit_Title.png"
            alt="profile"
          ></img>
        ) : (
          <img
            alt="profile"
            className="z-10 w-full max-w-[400px] md:max-w-[600px] rounded-full
              justify-items-center
            ring-2 ring-offset-2 ring-offset-dark-blue ring-white"
            src="assets/Press_Kit_Title.png"
          />
        )}
      </div>

      <div className="flex items-center justify-center mx-auto">
        <p className="text-cyan-500 ">
          (
          <a
            href="https://www.insulargames.com/gravitators"
            className="hover:text-orange-500 text-white underline"
          >
            click here
          </a>{" "}
          to download full press kit)
        </p>
      </div>

      {/* MAIN TEXT */}
      {/*<div className="z-30 basis-4/5 mt-96 md:mt-64 md: ml-96 mr-96 justify-center bg-white">
      <div className="mt-80 md:max-w-[750px] mx-auto rounded-md bg-steam-gray outline outline-white outline-1">*/}
      {/* HEADINGS */}
      <GravitatorsCardFAQ
        question={"FACTSHEET"}
        answer={`Game: Gravitators
        
        Developer: Insular Games

        Release date: November, 2021

        Platforms: Windows (Steam, Humble)

        File Size: 7.5 GB

        Standard Price: USD 9.99

        Website: www.insulargames.com`}
      ></GravitatorsCardFAQ>
      <GravitatorsCardFAQ
        question={"DESCRIPTION"}
        answer={
          <div>
            <p>{`Gravitators is a Gravity Shooter game where you pilot ships and complete mission objectives to secure the future of humankind. Defend the Earth and travel through the galaxy to repel a relentless alien invasion.
        
        Inspired by classic games like Thrust, Gravitar, Asteroids, Solar Jetman and others, Gravitators unshelves this old genre and overhauls it into modern times, while keeping the genre’s trademark features: maneuvering through tight spots while carrying heavy objects, fighting enemies and preserving fuel.
        

        `}</p>

            <p className="font-semibold font-redHatDisplay text-l py-3">
              SHIPS
            </p>

            <p>{`Each Gravitator ship has unique piloting controls and distinct weaponry.
        
        Flying each one completely changes the game experience, as the variations in thrusting and rotation controls and the weaponry require alternative combat strategies.
        
        Mastering all 4 Gravitator ships is already a challenge on its own.


        `}</p>

            <p className="font-semibold font-redHatDisplay text-l py-3">
              ENVIRONMENTS
            </p>

            <p>{`Travel throughout the Solar System and the Milky Way. Missions will take place on Earth, the Moon, asteroids, planets, exoplanets, outer space and even wormholes and black holes!
        
        Don’t let the battle prevent you from admiring the scenery though: it’s all beautifully done in Low Poly Art style, featuring an original soundtrack that will immerse you into all these locations.
        

        `}</p>

            <p className="font-semibold font-redHatDisplay text-l py-3">
              MISSIONS
            </p>

            <p>{`There is a huge variety of mission objectives: escort nuclear weapons, destroy enemy structures or bases, rush to save civilians from a doomed city, hack enemy terminals, collect samples, retrieve objects from exploding bases, chase escaping enemies, rescue human prisoners, steal enemy machinery, protect important targets, and many more!
        
        Experience gravity, but in its full mind-bending extent: pulling your ship down, up, left and right. Or no gravity at all, forcing you to thrust into the opposite direction to slow down!
        
        Missions will sometimes have clouds or gases that obstruct your view, underwater sections that will slow you down, and pitch black areas that hide lurking enemies. There are many, many obstacles and enemies that will try to stop you from completing missions.
        
        Exploring the battlefield can unlock hidden objectives and rewards.

        
        `}</p>

            <p className="font-semibold font-redHatDisplay text-l py-3">
              POWER UPS AND PERKS
            </p>

            <p>{`Every mission has 12 awards for pilots to achieve. Specific awards will unlock one of the 30 Power Ups and 30 Perks available to aid you in battle.
        
        Power Ups can increase your damage output, add effects to your weapons, increase your speed or hull, improve your ship armor and shield, or add certain useful abilities. Choosing the right power up for your mission can make a huge difference in its outcome.
        
        Perks permanently increase the stats of your ship or other human objects and structures.
        
        
        `}</p>
            <p className="font-semibold font-redHatDisplay text-l py-3">
              DIFFICULTY
            </p>

            <p>{`True to its genre, Gravitators is a difficult game. Scenarios are unforgiving and mistakes can be deadly. Missions will challenge your skills and reflexes.
        
        If you’ve never played this kind of games before, you should probably start the game in EASY and learn the ropes. Contrary to many games today, EASY mode is actually very challenging as well. And don’t worry: you can switch mission difficulty at any time, your progress won’t be affected by it.
        
        On the other hand, if you’re an experienced pilot looking for real challenge, HARD has been specifically tailored for you.
        
        `}</p>
          </div>
        }
      ></GravitatorsCardFAQ>

      <GravitatorsCardFAQ
        question={"VIDEO"}
        answer={
          <div>
            <p>Trailer (<Link
            to="https://youtu.be/-RXkFRBiZbo"
            target="_blank"
            className="hover:text-orange-500 text-white underline"
            >https://youtu.be/-RXkFRBiZbo</Link>):</p>
            <ReactPlayer
              url="https://youtu.be/-RXkFRBiZbo"
              playing="false"
              controls="true"
              light="true"
              volume={1}
              className="mx-auto justify-items-center bg-zinc-950"
              width="600px"
              height="337px"
            />
          </div>
        }
      ></GravitatorsCardFAQ>

      <GravitatorsCardFAQ
        question={"IMAGES"}
        answer={
          <div>
            <p>
              Download all in-game screenshots in all 3 resolutions as zip (26
              mb) from{" "}
              <Link
                to="https://www.insulargames.com/game_screenshots_2021/"
                className="hover:text-orange-500 text-white underline"
              >
                https://www.insulargames.com/game_screenshots_2021/
              </Link>
            </p>
            <p className="mt-8">1920 x 1080 (16:9)</p>
            <div className="grid grid-cols-3 auto-cols-max gap-1">
              <img
                className="mx-auto"
                src="assets\\1920x1080_Screenshot_01_City.jpg"
                alt="Gravitators City Screenshot"
              ></img>
              <img
                className="mx-auto"
                src="assets\\1920x1080_Screenshot_02_Fortress.jpg"
                alt="Gravitators Fortress on Earth Screenshot"
              ></img>
              <img
                className="mx-auto"
                src="assets\\1920x1080_Screenshot_03_Escort Nuke.jpg"
                alt="Gravitators Nuke Screenshot"
              ></img>
              <img
                className="mx-auto"
                src="assets\\1920x1080_Screenshot_04_Explosions.jpg"
                alt="Gravitators Explosions Screenshot"
              ></img>
              <img
                className="mx-auto"
                src="assets\\1920x1080_Screenshot_05_Darkness.jpg"
                alt="Gravitators Darkness Screenshot"
              ></img>
              <img
                className="mx-auto"
                src="assets\\1920x1080_Screenshot_06_Lava.jpg"
                alt="Gravitators Lava Screenshot"
              ></img>
              <img
                className="mx-auto"
                src="assets\\1920x1080_Screenshot_07_Fortress.jpg"
                alt="Gravitators Fortress Deep Space Screenshot"
              ></img>
              <img
                className="mx-auto"
                src="assets\\1920x1080_Screenshot_08_Beam.jpg"
                alt="Gravitators Beam Screenshot"
              ></img>
              <img
                className="mx-auto"
                src="assets\\1920x1080_Screenshot_09_Wormhole.jpg"
                alt="Gravitators Wormhole Screenshot"
              ></img>
            </div>

            <p className="mt-8">1440 x 1080 (4:3)</p>
            <div className="grid grid-cols-3 auto-cols-max gap-1">
              <img
                className="mx-auto"
                src="assets\\1440x1080_Screenshot_01_City.jpg"
                alt="Gravitators City Screenshot"
              ></img>
              <img
                className="mx-auto"
                src="assets\\1440x1080_Screenshot_02_Fortress.jpg"
                alt="Gravitators Fortress on Earth Screenshot"
              ></img>
              <img
                className="mx-auto"
                src="assets\\1440x1080_Screenshot_03_Escort Nuke.jpg"
                alt="Gravitators Nuke Screenshot"
              ></img>
              <img
                className="mx-auto"
                src="assets\\1440x1080_Screenshot_04_Explosions.jpg"
                alt="Gravitators Explosions Screenshot"
              ></img>
              <img
                className="mx-auto"
                src="assets\\1440x1080_Screenshot_05_Darkness.jpg"
                alt="Gravitators Darkness Screenshot"
              ></img>
              <img
                className="mx-auto"
                src="assets\\1440x1080_Screenshot_06_Lava.jpg"
                alt="Gravitators Lava Screenshot"
              ></img>
              <img
                className="mx-auto"
                src="assets\\1440x1080_Screenshot_07_Fortress.jpg"
                alt="Gravitators Fortress Deep Space Screenshot"
              ></img>
              <img
                className="mx-auto"
                src="assets\\1440x1080_Screenshot_08_Beam.jpg"
                alt="Gravitators Beam Screenshot"
              ></img>
              <img
                className="mx-auto"
                src="assets\\1440x1080_Screenshot_09_Wormhole.jpg"
                alt="Gravitators Wormhole Screenshot"
              ></img>
            </div>

            <p className="mt-8">1080 x 1080 (1:1)</p>
            <div className="grid grid-cols-3 auto-cols-max gap-1">
              <img
                className="mx-auto"
                src="assets\\1080x1080_Screenshot_01_City.jpg"
                alt="Gravitators City Screenshot"
              ></img>
              <img
                className="mx-auto"
                src="assets\\1080x1080_Screenshot_02_Fortress.jpg"
                alt="Gravitators Fortress on Earth Screenshot"
              ></img>
              <img
                className="mx-auto"
                src="assets\\1080x1080_Screenshot_03_Escort Nuke.jpg"
                alt="Gravitators Nuke Screenshot"
              ></img>
              <img
                className="mx-auto"
                src="assets\\1080x1080_Screenshot_04_Explosions.jpg"
                alt="Gravitators Explosions Screenshot"
              ></img>
              <img
                className="mx-auto"
                src="assets\\1080x1080_Screenshot_05_Darkness.jpg"
                alt="Gravitators Darkness Screenshot"
              ></img>
              <img
                className="mx-auto"
                src="assets\\1080x1080_Screenshot_06_Lava.jpg"
                alt="Gravitators Lava Screenshot"
              ></img>
              <img
                className="mx-auto"
                src="assets\\1080x1080_Screenshot_07_Fortress.jpg"
                alt="Gravitators Fortress Deep Space Screenshot"
              ></img>
              <img
                className="mx-auto"
                src="assets\\1080x1080_Screenshot_08_Beam.jpg"
                alt="Gravitators Beam Screenshot"
              ></img>
              <img
                className="mx-auto"
                src="assets\\1080x1080_Screenshot_09_Wormhole.jpg"
                alt="Gravitators Wormhole Screenshot"
              ></img>
            </div>

            <p className="mt-8">SHIPS & LOGOS</p>
            <div className="grid grid-cols-4 auto-cols-max gap-1 items-baseline">
              <img
                className="mx-auto "
                src="assets\\Ship_Fighter.png"
                alt="Gravitators Fighter Ship"
              ></img>
              <img
                className="mx-auto"
                src="assets\\Ship_Sparker.png"
                alt="Gravitators Sparker Ship"
              ></img>
              <img
                className="mx-auto"
                src="assets\\Ship_Lancer.png"
                alt="Gravitators Lancer Ship"
              ></img>
              <img
                className="mx-auto"
                src="assets\\Ship_Crusher.png"
                alt="Gravitators Crusher Ship"
              ></img>
              <img
                className="mx-auto"
                src="assets\\Ship_Fighter_Tag.png"
                alt="Gravitators Fighter Ship"
              ></img>
              <img
                className="mx-auto"
                src="assets\\Ship_Sparker_Tag.png"
                alt="Gravitators Sparker Ship"
              ></img>
              <img
                className="mx-auto"
                src="assets\\Ship_Lancer_Tag.png"
                alt="Gravitators Lancer Ship"
              ></img>
              <img
                className="mx-auto"
                src="assets\\Ship_Crusher_Tag.png"
                alt="Gravitators Crusher Ship"
              ></img>
            </div>

            
            <div className="">
              <img
                className="mx-auto "
                src="assets\\gravitators_logo_master_6042x2760.png"
                alt="Gravitators Game Logo (Glow)"
              ></img>
              <img
                className="mx-auto max-w-[400px]"
                src="assets\\Game_logo_plain.png"
                alt="Gravitators Icon (Glow)"
              ></img>
            </div>
          </div>
        }
      ></GravitatorsCardFAQ>

        <GravitatorsCardFAQ
        question={"ADDITIONAL INFO"}
        answer={
          <div>
          <p>PRESS COPY REQUEST</p>
          <p>Complete our contact form with your credentials and contact details, we’ll get back to you as soon as a press copy is available.</p>
          <p>{`
          `}</p>
          <p>MONETIZATION PERMISSION</p>
          <p>Insular Games allows for the contents of Gravitators to be published through video broadcasting services for any commercial or non-commercial purposes. Monetization of videos created containing assets from Gravitators is legally & explicitly allowed by Insular Games. This permission can be found in writing in:</p>
          <p>https://www.insulargames/gravitators#press%20kit</p>
          <p>{`
          `}</p>
          <p>OST & SFX</p>
          <p>Original Soundtrack by Cristián D’Agustini (<Link
          to="https://www.cristiandagustini.com"
          target="_blank"
          className="hover:text-orange-500 text-white underline"
          >https://www.cristiandagustini.com</Link>)</p>
          <p>{`
          `}</p>
          <p>Sound Effects by Ryan Coulburn Music & Sound (<Link
          to="https://www.facebook.com/RyanCoulburnMusicandSound"
          target="_blank"
          className="hover:text-orange-500 text-white underline"
          >https://www.facebook.com/RyanCoulburnMusicandSound</Link>)</p>
          <p>{`

          `}</p>
          <p>ABOUT INSULAR GAMES</p>
          <p>{`A group of game developers with different experiences and backgrounds but with a big passion for classic games decided to finally get together in 2015 and start doings games on their own.

While brainstorming for a name for the company, a lot of options were considered. But they finally settled for “insular”, which means: relating to or from an island. That made a lot of sense, given the team is located in the island of Java, Indonesia.

And thus Insular Games was founded.

Their first game is Gravitators, a gravity shooter game inspired by classics like Thrust, Gravitar and Solar Jetman.`}</p>
           <p>{`
          `}</p>
          <p>GRAVITATORS TEAM</p>
          <p>Apin Prastya</p>
          <p>Donny Irawan</p>
          <p>Jimmy Kristianto</p>
          <p>JP Maldonado</p>
          <p>Legowo Purnomo</p>
          <p>Rizal Ardianto</p>
          
          </div>
          }></GravitatorsCardFAQ>



    </section>
  );
};

export default GravitatorsPressKit;




function ErrorPage(){
    return(
    <>
    <main>
        <h1>An Error Occurred.</h1>
        <p>Could not find the page for ya.</p>
    </main>
    </>
    )
}
 
export default ErrorPage;
import LineGradient from "../components/LineGradient";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";
//import Card from "../components/Card";

const AlienPunks = () => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <section
      id="alien punks"
      className="md:justify-center md:h-full pt-36 pb-36"
    >
      {/* HEADINGS */}
      {isAboveLarge ? (
        <motion.div
          className="md:w-2/5 mx-auto text-center  my-auto"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -500 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <div>
            {/*<p className="font-playfair font-semibold text-4xl">GAMES</p>*/}
            <img src="assets\logo_alienpunk.png" alt="AlienPunks" />
            <div className="flex justify-center mt-2 mb-10">
              {/*<LineGradient width="w-2/3" />*/}
            </div>
          </div>
        </motion.div>
      ) : (
        <div className="md:w-2/5 mx-auto text-center  my-auto">
          <div>
            {/*<p className="font-playfair font-semibold text-4xl">GAMES</p>*/}
            <img src="assets\logo_alienpunk.png" alt="AlienPunks" />
            <div className="flex justify-center mt-2 mb-10">
              <LineGradient width="w-2/3" />
            </div>
          </div>
        </div>
      )}

      {/* ARTS */}

      <div>
        <img
          src="assets\alienpunks.gif"
          alt="Alien Punks GIF"
          className="w-96 justify-center mx-auto rounded-md outline outline-white outline-1"
        ></img>
      </div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
      >
        <div className="md:max-w-[750px] mx-auto rounded-md bg-steam-gray outline outline-white outline-1">
          <p className="font-redHatDisplay mt-12 mb-7 text-l text-start md:text-start justify-center px-4 pt-4">
            We were invited by the creators of this project to create 2D and 3D
            objects and mix them up by our proprietary Randomizer Tool to
            generate 10,000 NFTs of various rarities.
          </p>
          <p className="font-redHatDisplay mt-12 mb-7 text-l text-start md:text-start justify-center px-4 pb-4">
            For more information on what we did for this project, check the {" "}
            <a
              href="https://www.insulargames.com/alienpunks"
              target="_blank"
              className="hover:text-orange-500 text-cyan-500 underline"
            >
              Alien Punks page
            </a>
            .
          </p>
        </div>
      </motion.div>
    </section>
  );
};

export default AlienPunks;

//import SocialMediaIcons from "../components/SocialMediaIcons";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";
//import AnchorLink from "react-anchor-link-smooth-scroll";
//import lineClass from "../components/Line.css";
import { Carousel } from "react-carousel-minimal";
import ReactPlayer from "react-player/youtube";
//import Line from "../components/Line.jsx";
//import Tilt from "react-vanilla-tilt";
//import Tilt, { TiltOptions } from "vanilla-tilt";
//import Tilt from "react-vanilla-tilt";
//import LineGradient from "../components/LineGradient";

const data = [
  {
    image: "assets/1920x1080_Screenshot_01_City.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_02_Fortress.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_03_Escort Nuke.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_04_Explosions.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_05_Darkness.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_06_Lava.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_07_Fortress.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_08_Beam.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_09_Wormhole.jpg",
    caption: "",
  },
];

const captionStyle = {
  fontSize: "2em",
  fontWeight: "bold",
};
const slideNumberStyle = {
  fontSize: "20px",
  fontWeight: "bold",
};

const AlienPunksHome = ({ setSelectedPage }) => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <section id="overview" className="md:justify-center md:h-full pt-32">
      {/* IMAGE SECTION */}
      <div className="flex items-center justify-center mx-auto">
        {/*<div className=" z-10 mt-16 md:mt-36 mr-4 justify-center">*/}
        {isAboveLarge ? (
          <img
            className="md:justify-center md:max-w-[600px]"
            src="assets/logo_alienpunk.png"
            alt="profile"
          ></img>
        ) : (
          <img
            alt="profile"
            className="z-10 w-full max-w-[400px] md:max-w-[600px] rounded-full
              justify-items-center
            ring-2 ring-offset-2 ring-offset-dark-blue ring-white"
            src="assets/logo_alienpunk.png"
          />
        )}
      </div>
      <div className="flex items-center justify-center mx-auto mt-6">
        <img
          src="assets/Alien_Punks_v1_GIF.webp"
          alt=""
          className="md:justify-center md:max-w-[600px] outline outline-punks-box-border outline-1 rounded-md"
        ></img>
      </div>

      {/* MAIN TEXT */}
      {/*<div className="z-30 basis-4/5 mt-96 md:mt-64 md: ml-96 mr-96 justify-center bg-white">*/}
      <div className="mt-80 md:max-w-[750px] mx-auto rounded-md bg-punks-box-bg outline outline-punks-box-border outline-1 text-punks-box-border">
        {/* HEADINGS */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
        >
          <p className="font-redHatDisplay -mt-72 mb-7 text-l text-start md:text-start justify-center px-4 pt-4 ">
            We helped realize the 2 versions of the Alien Punks NFT collection:
          </p>
          <p
            className="font-redHatDisplay text-l text-start md:text-start justify-start px-4 pb-4"
            style={{ whiteSpace: "pre-line" }}
          >
            <a
              href="https://opensea.io/collection/cryptoalienpunkv1"
              target="_blank"
              className="hover:text-white text-punks-highlight underline"
            >
              Version 1
            </a>{" "}
            featured 10,000 portraits entirely made in Pixel Art.
          </p>
          <p className="font-redHatDisplay text-l text-start md:text-start justify-start px-4">
            <a
              href="https://opensea.io/collection/cryptoalienpunkv2"
              target="_blank"
              className="hover:text-white text-punks-highlight underline"
            >
              Version 2
            </a>{" "}
            featured 2,000 Voxel Models. We delivered 10,000 models in total,
            but the project was halted and not all versions were released.
          </p>
          <p className="font-redHatDisplay mt-8 mb-6 text-l text-start md:text-start justify-start px-4">
            To deliver this project, we built a tool that allowed mixing all the
            different variables (head, eyes, ears, mouth, neck, skin, clothing,
            accessories, background, etc.), ensuring that each combination
            worked OK with the others, and that each was unique (no repetition).
          </p>
          <p className="font-redHatDisplay mb-12 text-l text-start md:text-start justify-start px-4">
            All voxel models were done by our team.
          </p>
          <p className="font-redHatDisplay mb-12 text-l text-start md:text-start justify-start px-4 pb-4">
            Below you'll find a small sample of both projects.
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default AlienPunksHome;

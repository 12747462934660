//import SocialMediaIcons from "../components/SocialMediaIcons";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";
//import AnchorLink from "react-anchor-link-smooth-scroll";
//import lineClass from "../components/Line.css";
import { Carousel } from "react-carousel-minimal";
//import Line from "../components/Line.jsx";
//import Tilt from "react-vanilla-tilt";
//import Tilt, { TiltOptions } from "vanilla-tilt";
//import Tilt from "react-vanilla-tilt";
//import LineGradient from "../components/LineGradient";

const data = [
  {
    image: "assets/1920x1080_Screenshot_01_City.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_02_Fortress.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_03_Escort Nuke.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_04_Explosions.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_05_Darkness.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_06_Lava.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_07_Fortress.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_08_Beam.jpg",
    caption: "",
  },
  {
    image: "assets/1920x1080_Screenshot_09_Wormhole.jpg",
    caption: "",
  },
];

const captionStyle = {
  fontSize: "2em",
  fontWeight: "bold",
};
const slideNumberStyle = {
  fontSize: "20px",
  fontWeight: "bold",
};

const Landing = ({ setSelectedPage }) => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <section id="gravitators" className="md:justify-center md:h-full pt-28">
      {/* IMAGE SECTION */}
      <div className="flex items-center justify-center mx-auto">
        {/*<div className=" z-10 mt-16 md:mt-36 mr-4 justify-center">*/}
        {isAboveLarge ? (
          <img
            className="md:justify-center h-36"
            src="assets/gravitators_1024x240.png"
            alt="profile"
          ></img>
        ) : (
          <img
            alt="profile"
            className="z-10 w-full max-w-[400px] md:max-w-[600px] rounded-full
              justify-items-center
            ring-2 ring-offset-2 ring-offset-dark-blue ring-white"
            src="assets/insular_logo_web.png"
          />
        )}
      </div>
      <div className="md:-mt-16">
        <Carousel
          data={data}
          time={3000}
          width="850px"
          height="400px"
          captionStyle={captionStyle}
          radius="10px"
          slideNumber={false}
          slideNumberStyle={slideNumberStyle}
          captionPosition="bottom"
          automatic={true}
          dots={true}
          pauseIconColor="white"
          pauseIconSize="30px"
          slideBackgroundColor="black"
          slideImageFit="cover"
          thumbnails={false}
          thumbnailWidth="96px"
          style={{
            textAlign: "center",
            maxWidth: "750px",
            maxHeight: "400px",
            margin: "40px auto",
          }}
        />
      </div>

      <div className="flex items-center justify-center mx-auto h-1/4 md:-mt-6">
        <iframe
          title="Steam Widget"
          src="https://store.steampowered.com/widget/1321890/"
          frameborder="0"
          width="750"
          height="200"
        ></iframe>
      </div>

      {/* MAIN TEXT */}
      {/*<div className="z-30 basis-4/5 mt-96 md:mt-64 md: ml-96 mr-96 justify-center bg-white">*/}
      <div className="mt-80 md:max-w-[750px] mx-auto rounded-md bg-steam-gray outline outline-white outline-1">
        {/* HEADINGS */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
        >
          <p className="font-redHatDisplay -mt-72 mb-7 text-l text-start md:text-start justify-center px-4 pt-4">
            Gravitators is the first game we ever released on Steam.
          </p>
          <p className="font-redHatDisplay text-l text-start md:text-start justify-start px-4">
            It’s a modern take on a twin stick shooter, inspired by classics
            like Thrust or Solar Jetman. There are 4 playable ships (with
            different weapons and styles), and a variety of environments and
            hazards that will keep you engaged right until the end of the game.
          </p>
          <p className="font-redHatDisplay mt-8 mb-12 text-l text-start md:text-start justify-start px-4 pb-4">
            Learn more about it on the{" "}
            <a
              href="https://www.insulargames.com/gravitators"
              target="_blank"
              rel="noreferrer"
              className="hover:text-orange-500 text-cyan-500 underline"
            >
              Gravitators home page
            </a>
            !
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default Landing;

import {
  useGLTF,
  Stage,
  PresentationControls,
  SpotLight,
} from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import React, { Suspense } from "react";


const Alien3DCard = ({GLTFsrc, bgColor}) => {

    function Model(props) {
        const { scene } = useGLTF(GLTFsrc);
        return <primitive object={scene} />;
      }

  return (
    <div
      style={{ width: "363px", height: "363px" }}
      className={bgColor}
    >
      <Canvas pixelRatio={[1, 2]} camera={{ position: [27.5, 20, 15], fov: 35 }}>
        <ambientLight intensity={1.25} />
        <SpotLight
          distance={5}
          angle={0.15}
          attenuation={5}
          anglePower={5} // Diffuse-cone anglePower (default: 5)
        />
        <Suspense fallback={null}>
          <Model castShadows />
        </Suspense>
        <OrbitControls />
      </Canvas>
    </div>
  );
};

export default Alien3DCard;

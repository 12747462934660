import { motion } from "framer-motion";

const GravitatorsCardGIF = ({ cardtitle, cardimage, carddescription }) => {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.5 }}
      transition={{ duration: 0.5 }}
      variants={{
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
      }}
    >
      <div className=" mt-16 md:max-w-[600px] mx-auto rounded-md bg-gravitators-box-bg outline outline-gravitators-box-border outline-1">
        {/* HEADINGS */}

        <p
          className="font-redHatDisplay font-medium text-xl text-center md:text-center justify-center px-4 py-3
           text-gravitators-box-border border-b border-gravitators-box-border"
        >
          {cardtitle}
        </p>
        <img
          className="md:justify-center md:max-w-[600px] mx-auto"
          src={cardimage}
          alt="profile"
        />
        <p
          className="font-redHatDisplay text-l text-center px-4 py-2
          text-gravitators-box-border border-t border-gravitators-box-border"
        >
          {carddescription}
        </p>
      </div>
    </motion.div>
  );
};

export default GravitatorsCardGIF;
